@use "../config/" as *;
.tournament-video {
    @extend %c-blue-3-bg-10;
    .waf-listing {
        @extend %m-b-two-space;
        @extend %p-zero;
        @extend %c-blue-3-bg-10;
        .waf-head {
            @extend %p-x-full;
            @extend %p-y-two-space;
        }
        .title {
            @extend %capitalize;
            @extend %c-white-10;
            @extend %font-20-pb;
        }
        .article {
            &-list {
                @include card-count(1, var(--full-space));
            }
            &-item {
                position: relative;
                // @extend %p-b-full;
                @extend %w-100;
            }
            &-thumbnail {
                border: 0;
                @include border-radius(0);
            }
            &-meta .meta-date::before {
                content: unset;
            }
        }
        .pagination-wrap,
        .loadmore-wrap {
            @extend %d-none;
        }
        .no-results-msg {
            @extend %c-white-10;
        }
    }
    .filter {
        &-section {
            @extend %p-full;
        }
        &-label-group,
        &-hide-data {
            @extend %d-none;
        }
        &-search {
            .btn-close {
                .btn-text {
                    @extend %c-white-10;
                    &::after {
                        color: var(--c-blue-1);
                    }
                }
            }
        }
    }
}
.watch-center {
    .tournament-video {
        .first-list {
            .article-content {
                @extend %p-x-full;
            }
        }
    }
}
@media (min-width: $tablet-min-width) {
    .tournament-video {
        .waf-listing {
            margin-inline: var(--half-space-negative);
            .waf-head {
                padding-inline: 0;
            }
            .title {
                font-size: 3.6rem;
                line-height: 4.4rem;
            }
            .article {
                &-item {
                    padding-bottom: 0;
                }
                &-thumbnail {
                    border: .1rem solid hsl(var(--hsl-c-white)/0.7);
                    @include border-radius(var(--half-radius),hidden);
                }
                &-title {
                    font-weight: 400;
                    font-size: 2rem;
                    line-height: 2.4rem;
                    height: auto;
                }
            }
            .article-item {
                margin-bottom: var(--full-space);
            }
            .first-list {
                .article-item {
                    &:nth-child(5) {
                        width: 100%;
                        margin-bottom: var(--two-space);
                        .article {
                            &-info,
                            &-meta {
                                display: none;
                            }
                            &-title {
                                @include truncate-vertical(2, 24, null, $font-pb);
                            }
                            &-content {
                                width: 45%;
                                z-index: var(--zindex1);
                                @include position-combo('y-center', null, var(--full-space));
                            }
                            &-thumbnail {
                                border: 0;
                                .item-type-icon {
                                    right: auto;
                                    width: 7.5rem;
                                    height: 7.5rem;
                                    z-index: var(--zindex1);
                                    @include flex-config(flex, null, center, center);
                                    @include position-combo('center');
                                    &::before {
                                        content: unset;
                                    }
                                    &::after {
                                        font-size: 7.5rem;
                                    }
                                }
                                &:hover {
                                    .item-type-icon {
                                        &::before {
                                            content: unset;
                                        }
                                        &::after {
                                            transform: unset;
                                        }
                                    }
                                }
                            }
                        }
                        &::after,
                        &::before {
                            content: '';
                            height: 100%;
                            width: 33%;
                            z-index: var(--zindex1);
                            pointer-events: none;
                        }
                        &::after {
                            border-top-right-radius: var(--half-radius);
                            border-bottom-right-radius: var(--half-radius);
                            @include position-combo('tr');
                            @include linear-gradient(270deg, hsl(var(--hsl-c-blue-2)/0.9) 0%, hsl(var(--hsl-c-blue-2)/0) 100%);
                        }
                        &::before {
                            @include position-combo('tl');
                            @include linear-gradient(90deg, hsl(var(--hsl-c-blue) / 0.9) 0%, hsl(var(--hsl-c-blue) / 0) 90%);
                        }
                    }
                }
            }
            .first-list,
            .second-list {
                @include card-count(4, var(--one-n-half-space));
            }
        }
        .filter {
            &-section {
                position: relative;
                padding: 0;
                .select-box-wrap {
                    box-shadow: 0 0.4rem 1.6rem hsl(var(--hsl-c-white)/0.1);
                    padding-inline: var(--half-space);
                    @include border-radius(var(--half-radius));
                    @include bg(c-white, 4);
                    .list-item {
                        border-color: hsl(var(--hsl-c-white)/0.2);
                        a,
                        button {
                            @include color(c-white);
                        }
                    }
                    .list-item-option {
                        &:after {
                            border-color: var(--c-orange);
                        }
                    }
                }
            }
            &-search {
                top: 0;
                @include flex-config(flex);
            }
        }
    }
    .watch-center {
        .tournament-video {
            .filter {
                &-section {
                    .select-box-wrap {
                        -webkit-backdrop-filter: blur(3rem);
                        backdrop-filter: blur(3rem);
                    }
                }
                &-body {
                    width: calc(80% - var(--full-space));
                }
                &-group {
                    flex-basis: unset;
                    flex-wrap: wrap;
                }
            }
            .first-list {
                .article-content {
                    padding-inline: 0;
                }
            }
        }
    }
}